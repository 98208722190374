import React from "react";
import "./OrderDetailsPopup.css"; // Create this CSS file for styles

const OrderDetailsPopup = ({ order, onClose }) => {
    return (
        <div className="order-details-popup">
            <div className="popup-content">
                {/* Order ID Heading */}
                <h2>Order Details - Order ID: {order.id}</h2>

                {/* Table for displaying items */}
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.product.name}</td>
                                <td>{item.quantity}</td>
                                <td>₹{item.price}</td>
                                <td>₹{item.price * item.quantity}</td>{" "}
                                {/* Total for this item */}
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Summary */}
                <div className="order-summary">
                    <p>
                        <strong>Order Total: </strong>₹{order.total}
                    </p>
                    <p>
                        <strong>Date: </strong>
                        {new Date(order.created_at).toLocaleDateString("en-GB")}
                    </p>
                </div>

                {/* Close button */}
                <button
                    className="btn btn-sm btn-danger"
                    onClick={onClose}
                    style={{}}
                >
                    &times;
                </button>

                <button
                    className="btn btn-danger p-2 me-auto  gap-2 align-items-center"
                    style={{
                        background: "rgb(234, 0, 0)",
                        display: "inline-flex",
                    }}
                    type="button"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default OrderDetailsPopup;
