import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import { Pagination, Navigation, Thumbs, Zoom } from 'swiper/modules';
import './ProductDetailsPopup.css'; 
import DOMPurify from 'dompurify';

const ProductDetailsPopup = ({ product, productDetails = [], shopId, onClose, updateProSideCart, cartItems = [] }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [highlightedSection, setHighlightedSection] = useState(null);
    //const [attributes, setAttributes] = useState(product.id || []); // Use passed productDetails
    //const [attributes, setAttributes] = useState(product.id || null);
    const [attributes, setAttributes] = useState(product.id || []); // Adjust as per your data structure


    const [quantity, setQuantity] = useState(1);
    const [isZoomed, setIsZoomed] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [showQuantityControls, setShowQuantityControls] = useState(false);
    const [stockQuantity, setStockQuantity] = useState(0);
    const [message, setMessage] = useState("");

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;
        setCursorPosition({ x, y });
    };

    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };
    //console.log("attribute id:", attributes);
    // Check if product exists
    if (!product) return null;

    const images = Array.isArray(product.images) ? product.images : [];
    const initialImage = images.length > 0 
        ? images[0].image_path 
        : product.image 
            ? product.image 
            : "default-image.png";

    // Check if the product exists in the cart
    const cartProduct = cartItems.find(item => String(item.attribute.id) === String(product.id));
    //console.log("all details:", cartProduct.quantity);
    useEffect(() => {
        if (cartProduct) {
            setQuantity(cartProduct.quantity);
        } else {
            setQuantity(0);
        }
    }, [cartItems, product.id]);
   useEffect(() => {
        if (attributes) {
            setStockQuantity(product.stock_quantity || 0);
        }
    }, [attributes]);

    // Check if the item exists in the cart and set initial quantity
    useEffect(() => {
        const existingItem = cartItems.find(
            (item) =>
                item.product.id === product.product_id &&
                item.shopId === shopId &&
                item.attribute.id === product.id
        );
    
        if (existingItem) {
            setQuantity(existingItem.quantity);
            setShowQuantityControls(true);
        } else {
            setQuantity(1); // or some default value
            setShowQuantityControls(false);
        }
    }, [cartItems, product.id, product.product_id, shopId]);
    
    const handleThumbnailClick = (index) => {
        setActiveSlide(index); // Update the active slide index
        thumbsSwiper.slideTo(index); // Ensure the thumbnail swiper reflects the active slide
        // Update the main image slider
    swiperInstance.slideTo(index);
        
    };
    const updateCart = useCallback(
        (cartItems) => {
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
            updateProSideCart(cartItems); // Update cart in the parent
        },
        [updateProSideCart]
    );

    const handleAddToCart = useCallback(() => {
        if (!attributes) {
            toastr.error("No attributes available to add to cart", "Error");
            return;
        }
    
        if (product.stock_quantity <= 0) {
            toastr.error("Product is out of stock", "Error");
            return;
        }
    
        const updatedCartItems = [...cartItems];
        const itemIndex = updatedCartItems.findIndex(
            (item) =>
                item.product.id === product.product_id &&
                item.shopId === shopId &&
                item.attribute.id === product.id
        );
    
        if (quantity > 0) {
            if (itemIndex >= 0) {
                updatedCartItems[itemIndex].quantity = quantity;
            } else {
                updatedCartItems.push({
                    productDetails,
                    shopId,
                    attribute: { id: product.id }, // Ensure this matches your data structure
                    quantity,
                });
            }
            updateCart(updatedCartItems);
            setShowQuantityControls(true);
            toastr.success("Product added to cart!", "Success");
        } else {
            toastr.error("Quantity must be greater than 0", "Error");
        }
    }, [attributes, productDetails, shopId, quantity, cartItems, updateCart]);
    
    

    const handleQuantityChange = (change) => {
        const newQuantity = quantity + change;
    
        // Prevent quantity from being below 1 or above stock quantity
        if (newQuantity >= 1 && newQuantity <= product.stock_quantity) {
            setQuantity(newQuantity);
            handleAddToCart(newQuantity); // Call add to cart logic
        } else if (newQuantity > product.stock_quantity) {
            alert(`Maximum available stock is ${product.stock_quantity}`);
        }
    };
    
    
    

    const ucfirst = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className="product-details-popup">
            <div className="popup-content">
                <button className="close-btn" onClick={onClose}>
                    &times;
                </button>

                <h2 className="product-name">{ucfirst(productDetails.name)}</h2>

                {/* Main Product Image Slider with Zoom */}
                <div className="image-slider" onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Swiper
        modules={[Pagination, Navigation, Zoom]}
        pagination={{ clickable: true }}
        navigation
        zoom
        className="swiper-container-main"
        initialSlide={activeSlide}
        onSlideChange={({ activeIndex }) => setActiveSlide(activeIndex)}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
      >

                        {images.length > 0 ? (
                            images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className="image-wrapper">
                                        <img
                                            src={image.image_path}
                                            alt={`${productDetails.name} ${index + 1}`}
                                            className={`product-image ${highlightedSection === index ? 'highlight' : ''}`}
                                        />
                                        {isZoomed && (
                                            <div
                                                className="zoom-overlay"
                                                style={{
                                                    backgroundImage: `url(${image.image_path})`,
                                                    backgroundPosition: `${cursorPosition.x}% ${cursorPosition.y}%`,
                                                    backgroundSize: '200%', // Adjust zoom level here
                                                }}
                                            />
                                        )}
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <div className="image-wrapper">
                                    <img
                                        src={productDetails.image ? productDetails.image : "default-image.png"}
                                        alt="Default"
                                        className="product-image"
                                    />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>

                {/* Thumbnails */}
                <div className="thumbnail-slider">
                    <Swiper
                        modules={[Thumbs]}
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode
                        className="swiper-container-thumbs"
                    >
                        {images.length > 0 ? (
                            images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className="thumbnail-wrapper">
                                        <img
                                            src={image.image_path}
                                            alt={`${productDetails.name} thumbnail ${index + 1}`}
                                            className="thumbnail-image"
                                            onClick={() => handleThumbnailClick(index)}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <div className="thumbnail-wrapper">
                                    <img
                                        src={productDetails.image ? productDetails.image : "default-image.png"}
                                        alt="Default thumbnail"
                                        className="thumbnail-image"
                                    />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <p
  className="product-description"
  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(productDetails.description) }}
></p>
               
                <p className="product-price">Price: ₹{product.discount_cost}</p>

                {/* Quantity and Add to Cart */}
               {/* {cartProduct || quantity > 0 ? (
                    <div className="quantity-selector">
                        <button onClick={() => handleQuantityChange(-1)}>-</button>
                        <span>{quantity}</span>
                        <button onClick={() => handleQuantityChange(1)}>+</button>
                    </div>
                ) : (
                    <button className="add-to-cart-btn" onClick={() => handleQuantityChange(1)}>
                        Add to Cart
                    </button>
                )}  */}
            </div>
        </div>
    );
};

export default ProductDetailsPopup;
