import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Swiper from "swiper";
//import 'swiper/swiper-bundle.min.css'; // Ensure Swiper CSS is included
import CategorySection from "./CategorySection";
import TopSection from "./TopSection";
import CategoryDetailPage from "./CategoryDetailPage";
import LocationPopup from "./LocationPopup";
import ProductItem from "./ProductItem";
import PolicySection from "./PolicySection";
import CartSidebar from "./CartSidebar";
import PhoneOtpModal from "./PhoneOtpModal";
import UserAccountMenu from "./UserAccountMenu"; // Import UserAccountMenu component
import SliderSection from "./SliderSection";
import ProductDetailsPopup from "./ProductDetailsPopup"; // Import the popup component
import ProductDetails from "./ProductDetails";
import { weburl } from "../helpers/ApiUrls";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
//import PhoneOtpModal from './PhoneOtpModal'; // Import the PhoneOtpModal
import "./FindNearbyShops.css";
import TopSectionSkeleton from "./skeleton/TopSectionSkeleton";
import SliderSectionSkelton from "./skeleton/SliderSectionSkelton";
import CategorySectionSkelton from "./skeleton/CategorySectionSkelton";
import ShopSkeleton from "./skeleton/ShopSkeleton";
import { useThemeContext } from "../helpers/ThemeContext";

const FindNearbyShops = () => {
    const { cartItems, handleUpdateCart } = useThemeContext();

    const handleMouseEnter = () => {
        swiperRefLocal?.current?.swiper?.autoplay?.stop();
    };

    const handleMouseLeave = () => {
        swiperRefLocal?.current?.swiper?.autoplay?.start();
    };

    const [shops, setShops] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    // const [cartItems, setCartItems] = useState([]);
    const [isCartOpen, setIsCartOpen] = useState(false); // Ensure this is defined here
    const [isPhoneOtpModalVisible, setIsPhoneOtpModalVisible] = useState(false);
    const [isUserAccountMenuVisible, setIsUserAccountMenuVisible] =
        useState(false); // Define the state here
    const [categories, setCategories] = useState([]);
    const [homeCategories, setHomeCategories] = useState([]);
    const [slider, setSlider] = useState([]);
    //const [TopSection, setCategories] = useState([]);
    // Check if the user is authenticated
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductDetails, setSelectedProductDetails] = useState(null); // For product details
    const [showPopup, setShowPopup] = useState(false);
    const [shopId, setShopId] = useState(null); // Define shopId state
    const [ProLoad, setProLoad] = useState(true); // Define shopId state

    const [SearchCat, setSearchCat] = useState([]);

    const [SearchPro, setSearchPro] = useState([]);

    const handleProductClick = (productId, shopId) => {
        // Fetch product details based on productId and shopId
        // This is an example. Adjust the API call and response handling as needed

        axios
            .get(`${weburl}/api/products/${productId}/shop/${shopId}`)
            .then((response) => {
                if (response.data.success) {
                    setSelectedProduct(response.data.productAttribute);
                    setSelectedProductDetails(response.data.productDetails); // Store product attributes
                    setShopId(shopId); // Store the shopId for further use
                    setShowPopup(true);
                    if (response.data.productDetails.length === 0) {
                    }
                } else {
                    console.error(
                        "Failed to fetch product:",
                        response.data.message
                    );
                }
            })
            .catch((error) => {
                console.error("Error fetching product:", error);
            });
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSomeProduct(null); // Clear someProduct when closing the popup
        setSelectedProduct(null);
    };

    // Define someProduct in the parent component or where you are using it
    const [someProduct, setSomeProduct] = useState(null);

    // Set someProduct when needed, e.g., when a product is selected
    const handleProductSelect = (product) => {
        setSomeProduct(product);
    };
    useEffect(() => {
        const checkAuthStatus = () => {
            const token = localStorage.getItem("authToken"); // Or any key you use for storing the token
            if (token) {
                // You may also perform token validation here
                setIsUserAccountMenuVisible(true);
            } else {
                setIsUserAccountMenuVisible(false);
            }
        };

        checkAuthStatus();
    }, []);
    //const [isPhoneOtpModalOpen, setIsPhoneOtpModalOpen] = useState(false);
    useEffect(() => {
        const handleLocationFetched = (event) => {
            const { location, latitude, longitude } = event.detail;
            // Log or alert the values to ensure they're correct
            console.log("Latitude:", latitude, "Longitude:", longitude);
            //alert(`Latitude: ${latitude}, Longitude: ${longitude}`);
            fetchAndDisplayData(location, latitude, longitude);
            fetchAndDisplayDataPro(location, latitude, longitude);
        };

        window.addEventListener("locationFetched", handleLocationFetched);

        return () => {
            window.removeEventListener(
                "locationFetched",
                handleLocationFetched
            );
        };
    }, []);
    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = src;
                script.async = true;
                script.onload = () => resolve();
                script.onerror = () =>
                    reject(new Error(`Failed to load script: ${src}`));
                document.body.appendChild(script);
            });
        };

        const checkLocation = async () => {
            try {
                const response = await fetch(`${weburl}/get-saved-location`);
                const data = await response.json();
                if (data.success && data.location) {
                    setLocation({
                        address: data.location,
                        latitude: data.latitude,
                        longitude: data.longitude,
                    });
                    fetchAndDisplayData(
                        data.location,
                        data.latitude,
                        data.longitude
                    );
                } else {
                    // Update the cart display
                    const cartDiv = document.getElementById("mycartup");
                    if (cartDiv) {
                        cartDiv.innerHTML = `My Cart`; // Change to desired text
                    }
                    handleUpdateCart([]); // Call the cart update function to clear the cart

                    setShops([]);
                    setPopupVisible(true);
                }
            } catch (error) {
                console.error("Error fetching saved location:", error);
                // Update the cart display
                const cartDiv = document.getElementById("mycartup");
                if (cartDiv) {
                    cartDiv.innerHTML = `My Cart`; // Change to desired text
                }
                handleUpdateCart([]); // Call the cart update function to clear the cart

                setShops([]);
                setPopupVisible(true);
            }
        };

        loadScript(`${weburl}/frontend/assets/js/vendor.js`)
            .then(() => loadScript(`${weburl}/frontend/assets/js/active.js`))
            .then(() => {
                console.log("Scripts loaded successfully");
                checkLocation();
                fetchCartItems();
            })
            .catch((error) => {
                console.error("Error loading scripts:", error);
                // Update the cart display
                const cartDiv = document.getElementById("mycartup");
                if (cartDiv) {
                    cartDiv.innerHTML = `My Cart`; // Change to desired text
                }
                handleUpdateCart([]); // Call the cart update function to clear the cart

                setShops([]);
                setPopupVisible(true);
            });
    }, []);
    useEffect(() => {
        fetchCartItems();
    }, []);
    // useEffect(() => {
    //     const swiper = new Swiper(".swiper-container", {
    //         slidesPerView: 3,
    //         spaceBetween: 20,
    //         centeredSlides: true,
    //         navigation: {
    //             nextEl: ".swiper-button-next",
    //             prevEl: ".swiper-button-prev",
    //         },
    //         pagination: {
    //             el: ".swiper-pagination",
    //             clickable: true,
    //         },
    //     });
    // }, [shops]);

    useEffect(() => {
        // Fetch categories from an API or pass it directly
        fetch(`${weburl}/api/categories`)
            .then((response) => response.json())
            .then((data) => setCategories(data));
    }, []);

    useEffect(() => {
        // Fetch categories from an API or pass it directly
        fetch(`${weburl}/api/home_categories`)
            .then((response) => response.json())
            .then((data) => setHomeCategories(data));
    }, []);
    useEffect(() => {
        // Fetch slider data from the Laravel API
        axios
            .get(`${weburl}/api/sliders`)
            .then((response) => {
                setSlider(response.data);
            })
            .catch((error) => {
                console.error(
                    "There was an error fetching the slider data!",
                    error
                );
            });
    }, []);
    const fetchAndDisplayData = async (location, latitude, longitude) => {
        //alert(latitude);
        setLoading(true);
        try {
            const response = await axios.post(`${weburl}/find-nearby-shops`, {
                latitude,
                longitude,
                _token: document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content"),
            });

            if (response.data.success) {
                const shops = response.data.shops || [];
                const productsByCategory =
                    response.data.productsByCategory || {};
                const categories = response.data.categories || [];

                const enrichedShops = shops.map((shop) => ({
                    ...shop,
                    categories: categories
                        .filter((category) =>
                            Object.keys(productsByCategory).includes(
                                category.id.toString()
                            )
                        )
                        .map((category) => ({
                            ...category,
                            products: productsByCategory[category.id] || [],
                        })),
                }));
                setShops(enrichedShops);
                setError(null);
                document.getElementById("displayedLocation").innerText =
                    location;
                setPopupVisible(false);
            } else {
                setError(response.data.message || "No nearby shops found.");
                // Update the cart display
                const cartDiv = document.getElementById("mycartup");
                if (cartDiv) {
                    cartDiv.innerHTML = `My Cart`; // Change to desired text
                }
                handleUpdateCart([]); // Call the cart update function to clear the cart

                setShops([]);
                setPopupVisible(true);
            }
            setProLoad(false);
        } catch (error) {
            setShops([]);
            setProLoad(false);
            console.error("Error fetching nearby shops:", error);
            setError(
                error.response?.data?.message ||
                    "An error occurred while fetching nearby shops."
            );
            // Update the cart display
            const cartDiv = document.getElementById("mycartup");
            if (cartDiv) {
                cartDiv.innerHTML = `My Cart`; // Change to desired text
            }

            handleUpdateCart([]); // Call the cart update function to clear the cart
            setShops([]);
            setPopupVisible(true);
            setPopupVisible(false);
        } finally {
            setLoading(false);
        }
    };

    const [SearchData, SetSearchData] = useState({
        // Your data object here
        success: true,
        products: [
            // products array
        ],
        categories: [
            // categories array
        ],
        // shops array
    }); // Define shopId state

    const fetchAndDisplayDataPro = async (location, latitude, longitude) => {
        //alert(latitude);
        setLoading(true);
        try {
            const response = await axios.post(
                `${weburl}/find-nearby-shops-pro`,
                {
                    latitude,
                    longitude,
                    _token: document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                }
            );

            if (response.data.success) {
                console.log("response.data.success", response.data);
                SetSearchData(response.data);
            } else {
                SetSearchData({
                    // Your data object here
                    success: true,
                    products: [
                        // products array
                    ],
                    categories: [
                        // categories array
                    ],
                    // shops array
                });
            }
        } catch (error) {
            console.error("Error fetching pro nearby shops:", error);
        } finally {
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        // setSearchTerm(event.target.value);
    };

    const filterResults = () => {
        const lowercasedTerm = searchTerm.toLowerCase();

        const filteredProducts = SearchData?.products.filter(
            (product) =>
                product.name.toLowerCase().includes(lowercasedTerm) ||
                product.description.toLowerCase().includes(lowercasedTerm)
        );

        const filteredCategories = SearchData?.categories.filter((category) =>
            category.name.toLowerCase().includes(lowercasedTerm)
        );

        return { filteredProducts, filteredCategories };
    };

    const { filteredProducts, filteredCategories } = filterResults();

    useEffect(() => {
        const searchlist = document.getElementById("searchlist");
        const searchInput = document.getElementById("searchinput");
        if (searchInput) {
            searchInput.addEventListener("input", function () {
                console.log(searchInput.value);
                setSearchTerm(searchInput.value);
                if (searchInput.value === "") {
                    searchlist.style.display = "none";
                } else {
                    searchlist.style.display = "block";
                }
            });
        }

        if (searchlist) {
            // Update the search results when the searchTerm changes
            updateSearchResults(searchlist);
        }
    }, [searchTerm]);

    const updateSearchResults = (searchlist) => {
        const { filteredProducts, filteredCategories } = filterResults();

        // Clear previous results
        searchlist.innerHTML = "";

        if (filteredProducts.length > 0 || filteredCategories.length > 0) {
            const productList = document.createElement("div");
            const categoryList = document.createElement("div");

            if (filteredProducts.length > 0) {
                const productHeader = document.createElement("h4");
                productHeader.innerText = "Products";
                productList.appendChild(productHeader);

                const ulProducts = document.createElement("ul");
                filteredProducts.forEach((product) => {
                    const li = document.createElement("li");
                    const a = document.createElement("a");
                    a.href = `${weburl}/product/${product.slug}`;
                    a.innerText = product.name;
                    li.appendChild(a);
                    ulProducts.appendChild(li);
                });
                productList.appendChild(ulProducts);
            }

            if (filteredCategories.length > 0) {
                const categoryHeader = document.createElement("h4");
                categoryHeader.innerText = "Categories";
                categoryList.appendChild(categoryHeader);

                const ulCategories = document.createElement("ul");
                filteredCategories.forEach((category) => {
                    const li = document.createElement("li");
                    const a = document.createElement("a");
                    a.href = `${weburl}/shop/${category.slug}`;
                    a.innerText = category.name;
                    li.appendChild(a);
                    ulCategories.appendChild(li);
                });
                categoryList.appendChild(ulCategories);
            }

            searchlist.appendChild(productList);
            searchlist.appendChild(categoryList);
        } else {
            const noResults = document.createElement("p");
            noResults.innerText = "No results found";
            searchlist.appendChild(noResults);
        }
    };

    const saveLocation = async (location, lat, lon) => {
        try {
            const response = await fetch(`${weburl}/save-location`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-TOKEN": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
                body: JSON.stringify({
                    location,
                    latitude: lat,
                    longitude: lon,
                }),
            });
            const data = await response.json();
            if (data.success) {
                fetchAndDisplayData(location, lat, lon);
            } else {
                alert(
                    data.message || "Failed to save location. Please try again."
                );
            }
        } catch (error) {
            console.error("Error saving location:", error);
            alert(
                "An error occurred while saving the location. Please check your connection and try again."
            );
        }
    };

    const detectLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lon = position.coords.longitude;

                    try {
                        const response = await fetch(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                        );
                        const data = await response.json();
                        const location =
                            data.results[0]?.formatted_address ||
                            "Unknown location";
                        setLocation({
                            address: location,
                            latitude: lat,
                            longitude: lon,
                        });
                        saveLocation(location, lat, lon);
                    } catch (error) {
                        console.error(
                            "Error fetching location address:",
                            error
                        );
                        alert(
                            "Failed to fetch location address. Please try again."
                        );
                    }
                },
                (error) => {
                    console.error("Geolocation error:", error);
                    alert(
                        "Geolocation permission denied. Please enable location services to use this feature."
                    );
                    // Update the cart display
                    const cartDiv = document.getElementById("mycartup");
                    if (cartDiv) {
                        cartDiv.innerHTML = `My Cart`; // Change to desired text
                    }
                    handleUpdateCart([]); // Call the cart update function to clear the cart

                    setShops([]);
                    setPopupVisible(true);
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
            // Update the cart display
            const cartDiv = document.getElementById("mycartup");
            if (cartDiv) {
                cartDiv.innerHTML = `My Cart`; // Change to desired text
            }
            handleUpdateCart([]); // Call the cart update function to clear the cart

            setShops([]);
            setPopupVisible(true);
        }
    };

    const handleAddToCart = async (item) => {
        try {
            const response = await axios.post(`${weburl}/api/add-to-cart`, {
                product: item.product,
                shopId: item.shopId,
                attribute: item.attribute,
                quantity: item.quantity,
                _token: document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content"),
            });

            if (response.data.success) {
                const updatedCartItems = [...cartItems];
                const existingItemIndex = updatedCartItems.findIndex(
                    (cartItem) =>
                        cartItem.product.id === item.product.id &&
                        cartItem.attribute.id === item.attribute.id &&
                        cartItem.shopId === item.shopId
                );

                if (existingItemIndex >= 0) {
                    updatedCartItems[existingItemIndex].quantity +=
                        item.quantity;
                } else {
                    updatedCartItems.push({
                        product: item.product,
                        shopId: item.shopId,
                        attribute: item.attribute,
                        quantity: item.quantity,
                    });
                }

                //  setCartItems(updatedCartItems);
                localStorage.setItem(
                    "cartItems",
                    JSON.stringify(updatedCartItems)
                );
            } else {
                console.error(
                    "Error adding item to cart:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error adding item to cart:", error);
        }
    };

    const fetchCartItems = () => {
        const storedCartItems = localStorage.getItem("cartItems");
        if (storedCartItems) {
            //     setCartItems(JSON.parse(storedCartItems));
        }
    };

    // const handleUpdateCart = (updatedCartItems) => {
    //     setCartItems(updatedCartItems);
    //     localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    // };

    const handleVerifyPhone = (phoneNumber) => {
        console.log("Phone number verified:", phoneNumber);
        setIsPhoneOtpModalVisible(false);
        console.log("Setting UserAccountMenu visibility to true");
        setIsUserAccountMenuVisible(true); // Show UserAccountMenu upon successful verification
        console.log(
            "After setting UserAccountMenu visibility:",
            isUserAccountMenuVisible
        );

        // Proceed with checkout or other actions
    };

    useEffect(() => {
        console.log(
            "UserAccountMenu visibility changed:",
            isUserAccountMenuVisible
        );
    }, [isUserAccountMenuVisible]);

    const openPhoneOtpModal = () => setIsPhoneOtpModalVisible(true);
    const closePhoneOtpModal = () => setIsPhoneOtpModalVisible(false);
    const [searchQuery, setSearchQuery] = useState(""); // New state for search

    useEffect(() => {
        if (shops && shops[0]?.categories) {
            // Extract categories and products from shops
            setSearchCat(shops[0]?.categories); // Set categories

            // Safeguard against undefined categories
            const allProducts = shops[0]?.categories.map(
                (category) => category.products || []
            );

            setSearchPro(allProducts); // Set all products
            // Logging for debugging
            console.log("Categories:", shops[0]?.categories);
            console.log("Products:", allProducts);
        }

        console.log("shops:", shops[0]);
    }, [shops]); // Only depend on shops

    useEffect(() => {
        const loginbutton = document.getElementById("loginclick");
        const token = localStorage.getItem("authToken");

        loginbutton.addEventListener("click", function () {
            if (!token) {
                openPhoneOtpModal(true);
            }
        });
    });

    return (
        <div>
            {slider.length === 0 ? (
                <SliderSectionSkelton />
            ) : (
                <SliderSection slider={slider} />
            )}

            {/* {categories.length === 0 ? (
                <TopSectionSkeleton />
            ) : (
                <TopSection homeCategories={homeCategories} />
            )} */}

            {categories.length === 0 ? (
                <CategorySectionSkelton />
            ) : (
                <CategorySection categories={categories} />
            )}

            <div className="find-nearby-shops w-100 d-block">
                {popupVisible && (
                    <LocationPopup
                        onClose={() => setPopupVisible(false)}
                        onDetectLocation={detectLocation}
                    />
                )}

                <div className="shops-container">
                    {ProLoad ? (
                        Array.from({ length: 2 }).map((_, index) => (
                            <ShopSkeleton index={index} />
                        ))
                    ) : !ProLoad && shops.length === 0 ? (
                        <>
                            <div
                                className="alert alert-danger  text-center align-items-center"
                                role="alert"
                            >
                                <img
                                    src={`${weburl}/assets/images/no-order.png`}
                                    className="d-block mx-auto"
                                    style={{
                                        maxWidth: 70,
                                        marginRight: 10,
                                    }}
                                />

                                <h3>We don't have delivery at this location</h3>
                            </div>
                        </>
                    ) : (
                        shops.map((shop) => (
                            <div key={shop.id} className="shop">
                                <h3>{shop.name}</h3>
                                {shop.categories.map((category) => (
                                    <div key={category.id} className="category">
                                        <h4>{category.name}</h4>

                                        <div className=" my-4 hero-swiper ">
                                            <Swiper
                                                breakpoints={{
                                                    300: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 10, // Set the gap between slides for window width <= 400px
                                                    },
                                                    768: {
                                                        slidesPerView: 5,
                                                        spaceBetween: 15, // Set the gap between slides for window width <= 768px
                                                    },
                                                    992: {
                                                        slidesPerView: 6,
                                                        spaceBetween: 15, // Set the gap between slides for window width <= 992px
                                                    },
                                                    1200: {
                                                        slidesPerView: 7,
                                                        spaceBetween: 20, // Set the gap between slides for window width <= 1200px
                                                    },
                                                }}
                                                modules={[
                                                    Pagination,
                                                    Navigation,
                                                ]}
                                                className="swiper-wrapper"
                                            >
                                                {category.length === 0
                                                    ? Array.from({
                                                          length: 3,
                                                      }).map((_, index) => (
                                                          <SwiperSlide>
                                                              <div
                                                                  className="skeleton w-100"
                                                                  style={{
                                                                      width: "100%",
                                                                      maxWidth:
                                                                          "100%",
                                                                      height: "auto",
                                                                      aspectRatio:
                                                                          "1/0.3",
                                                                  }}
                                                              ></div>
                                                          </SwiperSlide>
                                                      ))
                                                    : category.products.map(
                                                          (product, index) => (
                                                              <SwiperSlide
                                                                  key={index}
                                                              >
                                                                  <ProductItem
                                                                      key={
                                                                          product.id
                                                                      }
                                                                      product={
                                                                          product
                                                                      }
                                                                      shopId={
                                                                          shop.id
                                                                      }
                                                                      onProductClick={
                                                                          handleProductClick
                                                                      } // Ensure this prop is passed
                                                                      //onUpdateCart={handleUpdateCart}
                                                                      cartItems={
                                                                          cartItems
                                                                      }
                                                                      updateSideCart={
                                                                          handleUpdateCart
                                                                      } // Pass updateSideCart here
                                                                  />
                                                              </SwiperSlide>
                                                          )
                                                      )}
                                            </Swiper>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    )}
                </div>
            </div>

            {showPopup && selectedProduct && (
                <ProductDetailsPopup
                    product={selectedProduct}
                    productDetails={selectedProductDetails} // Pass the product details/attributes
                    shopId={shopId}
                    onClose={handleClosePopup}
                    cartItems={cartItems} // Assuming you have cartItems state
                    updateProSideCart={handleUpdateCart} // Pass updateSideCart here
                />
            )}
            <PolicySection />

            <CartSidebar
                cartItems={cartItems}
                onUpdateCart={handleUpdateCart}
                isOpen={isCartOpen}
                onClose={() => setIsCartOpen(false)}
                onProceedToCheckout={openPhoneOtpModal} // Pass down function to CartSidebar
            />

            <PhoneOtpModal
                isOpen={isPhoneOtpModalVisible}
                onClose={() => setIsPhoneOtpModalVisible(false)}
                onVerify={handleVerifyPhone}
            />

            {isUserAccountMenuVisible && <UserAccountMenu />}
        </div>
    );
};

export default FindNearbyShops;
