// src/components/LocationPopup.jsx
import React from 'react';
import './LocationPopup.css';

const LocationPopup = ({ onConfirm }) => {
    return (
        
        <div className="location-popup">
            <h2>No Near Shop Found</h2>
           
        </div>
    );
};

export default LocationPopup;
