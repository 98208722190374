// BlogContext.js
import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import toast from "react-hot-toast";
const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [stockQuantity, setStockQuantity] = useState(0);
    const [selectedAttribute, setSelectedAttribute] = useState(null);

    useEffect(() => {
        const storedItems = localStorage.getItem("cartItems");
        if (storedItems == "undefined") {
            setCartItems([]);
        } else {
            // Parse the stored items from local storage
            const parsedItems = storedItems ? JSON.parse(storedItems) : [];
            if (parsedItems.length > 0) {
                setCartItems(parsedItems); // Update the state with parsed items
            }
        }
    }, []); // Run only on mount

    useEffect(() => {
        // Parse the stored items from local storage
        const parsedItems = cartItems;

        if (parsedItems.length > 0) {
            // Calculate total quantity and total items
            const totalQuantity = parsedItems.reduce(
                (acc, item) => acc + item.quantity,
                0
            );
            const totalItems = parsedItems.length;

            const totalAmount = parsedItems.reduce(
                (acc, item) =>
                    acc + (item.attribute.discount_cost || 0) * item.quantity,
                0
            );

            // Update the cart display
            const cartDiv = document.getElementById("mycartup");
            if (cartDiv) {
                cartDiv.innerHTML = `<p>
                ${totalQuantity}  items </p>  <p> ₹${totalAmount} </p>`; // Change to desired text
            }
        } else {
            // Update the cart display
            const cartDiv = document.getElementById("mycartup");
            if (cartDiv) {
                cartDiv.innerHTML = `<p> My cart </p>`; // Change to desired text
            }
        }
    }, [cartItems]); // Run only on mount

    const handleUpdateCart = (updatedCartItems) => {
        setCartItems(updatedCartItems);
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    };

    const handleQuantityChange = (product, shopId, attribute, newQuantity) => {
        console.log("product", product);
        console.log("shopId", shopId);
        console.log("attribute", attribute);
        console.log("newQuantity", newQuantity);

        if (!Number.isInteger(newQuantity)) {
            toast.error("Invalid quantity");
            return;
        }

        const stockQuantity = attribute.stock_quantity || Infinity;

        if (newQuantity > stockQuantity) {
            toast.error(`Cannot exceed stock quantity of ${stockQuantity}`);
            return;
        }

        let updatedCartItems;

        if (newQuantity === 0) {
            // Remove item from cart
            updatedCartItems = cartItems.filter(
                (item) =>
                    !(
                        item.product.id === product.id &&
                        item.shopId === shopId &&
                        item.attribute.id === attribute.id
                    )
            );
        } else {
            // Update quantity
            updatedCartItems = cartItems.map((item) => {
                if (
                    item.product.id === product.id &&
                    item.shopId === shopId &&
                    item.attribute.id === attribute.id
                ) {
                    return { ...item, quantity: newQuantity };
                }
                return item;
            });
        }

        handleUpdateCart(updatedCartItems);
    };

    const handleAddToCart = (selectedAttribute, product, shopId) => {
        if (selectedAttribute) {
            if (selectedAttribute.stock_quantity > 0) {
                const updatedCartItems = [...cartItems];
                const itemIndex = updatedCartItems.findIndex(
                    (item) =>
                        item.product.id === product &&
                        item.shopId === shopId &&
                        item.attribute.id === selectedAttribute
                );
                if (quantity > 0) {
                    if (itemIndex >= 0) {
                        updatedCartItems[itemIndex].quantity = quantity;
                    } else {
                        updatedCartItems.push({
                            product,
                            shopId,
                            attribute: selectedAttribute,
                            quantity,
                        });
                    }
                    if (updatedCartItems[itemIndex]?.quantity === 0) {
                        updatedCartItems.splice(itemIndex, 1);
                    }
                    handleUpdateCart(updatedCartItems);
                } else {
                    toast.error("Quantity must be greater than 0");
                }
            } else {
                toast.error("Product is out of stock");
            }
        } else {
            toast.error("No attribute selected");
        }
    };

    const handleDeleteItem = (product, shopId, attribute) => {
        const updatedCartItems = cartItems.filter(
            (item) =>
                !(
                    item.product.id === product.id &&
                    item.shopId === shopId &&
                    item.attribute.id === attribute.id
                )
        );

        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

        handleUpdateCart(updatedCartItems);
    };

    return (
        <ThemeContext.Provider
            value={{
                cartItems,
                handleUpdateCart,
                handleQuantityChange,
                handleAddToCart,
                handleDeleteItem,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useThemeContext must be used within a ThemeProvider");
    }
    return context;
};
