import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./ProductItem.css";
import { weburl } from "../helpers/ApiUrls";
import { useThemeContext } from "../helpers/ThemeContext";
import toast from "react-hot-toast";
const ProductItem = ({ product, shopId }) => {
    const {
        cartItems,
        handleUpdateCart,
        handleQuantityChange,
        handleAddToCart,
    } = useThemeContext();

    const [attributes, setAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [showQuantityControls, setShowQuantityControls] = useState(false);
    const [stockQuantity, setStockQuantity] = useState(0);
    const [message, setMessage] = useState("");

    // Fetch product attributes
    useEffect(() => {
        if (product.product_attributes.length > 0) {
            setSelectedAttribute(product.product_attributes[0]);
            setStockQuantity(product.product_attributes[0].stock_quantity || 0);
            setAttributes(product.product_attributes);
        }

        // const fetchAttributes = async () => {
        //     try {
        //         const response = await axios.get(
        //             `${weburl}/api/attributes/${product.id}/${shopId}`
        //         );
        //         if (response.data.success) {
        //             setAttributes(response.data.attributes);
        //             if (response.data.attributes.length > 0) {
        //                 setSelectedAttribute(response.data.attributes[0]);
        //                 setStockQuantity(
        //                     response.data.attributes[0].stock_quantity || 0
        //                 );
        //             }
        //         } else {
        //             console.error(
        //                 "Failed to fetch attributes:",
        //                 response.data.message
        //             );
        //         }
        //     } catch (error) {
        //         console.error("Error fetching attributes:", error);
        //     }
        // };
        // fetchAttributes();
    }, [product.id, shopId]);

    // Update quantity controls based on existing cart items
    useEffect(() => {
        const existingItem = cartItems.find(
            (item) =>
                item.product.id === product.id &&
                item.shopId === shopId &&
                item.attribute.id === selectedAttribute?.id
        );
        if (existingItem) {
            setQuantity(existingItem.quantity);
            setShowQuantityControls(true);
        } else {
            setQuantity(1);
            setShowQuantityControls(false);
        }
    }, [cartItems, selectedAttribute, product.id, shopId]);

    const handleAttributeChange = (e) => {
        const selected = attributes.find(
            (attr) => attr.id === parseInt(e.target.value)
        );
        setSelectedAttribute(selected);
        setStockQuantity(selected.stock_quantity || 0);
    };

    // const updateCart = useCallback(
    //     (cartItems) => {
    //         localStorage.setItem("cartItems", JSON.stringify(cartItems));
    //         updateSideCart(cartItems);
    //     },
    //     [updateSideCart]
    // );

    // const handleAddToCart = useCallback(() => {
    //     if (selectedAttribute) {
    //         if (selectedAttribute.stock_quantity > 0) {
    //             const updatedCartItems = [...cartItems];
    //             const itemIndex = updatedCartItems.findIndex(
    //                 (item) =>
    //                     item.product.id === product.id &&
    //                     item.shopId === shopId &&
    //                     item.attribute.id === selectedAttribute.id
    //             );
    //             if (quantity > 0) {
    //                 if (itemIndex >= 0) {
    //                     updatedCartItems[itemIndex].quantity = quantity;
    //                 } else {
    //                     updatedCartItems.push({
    //                         product,
    //                         shopId,
    //                         attribute: selectedAttribute,
    //                         quantity,
    //                     });
    //                 }
    //                 if (updatedCartItems[itemIndex]?.quantity === 0) {
    //                     updatedCartItems.splice(itemIndex, 1);
    //                 }
    //                 handleUpdateCart(updatedCartItems);
    //                 setShowQuantityControls(true);
    //             } else {
    //                 toast.error("Quantity must be greater than 0");
    //             }
    //         } else {
    //             toast.error("Product is out of stock");
    //         }
    //     } else {
    //         toast.error("No attribute selected");
    //     }
    // }, [
    //     selectedAttribute,
    //     product,
    //     shopId,
    //     quantity,
    //     cartItems,
    //     handleUpdateCart,
    // ]);

    // Handle quantity changes
    // const handleQuantityChange = useCallback((increment) => {
    //     setQuantity((prevQty) => {
    //         const newQuantity = Math.max(
    //             0,
    //             Math.min(stockQuantity, prevQty + increment)
    //         );
    //         const updatedCartItems = [...cartItems];
    //         if (selectedAttribute) {
    //             const itemIndex = updatedCartItems.findIndex(
    //                 (item) =>
    //                     item.product.id === product.id &&
    //                     item.shopId === shopId &&
    //                     item.attribute.id === selectedAttribute.id
    //             );
    //             if (itemIndex >= 0) {
    //                 if (newQuantity === 0) {
    //                     updatedCartItems.splice(itemIndex, 1);
    //                 } else {
    //                     updatedCartItems[itemIndex].quantity = newQuantity;
    //                 }
    //                 updateCart(updatedCartItems);
    //             }
    //         }
    //         if (newQuantity >= stockQuantity) {
    //             setMessage(`Limit of ${stockQuantity} reached.`);
    //         } else {
    //             setMessage("");
    //         }
    //         return newQuantity;
    //     });
    // });

    return (
        <div className="product-item p-0 m-0">
            <figure className="product-thumb">
                <Link to={`${weburl}/product/${product.slug}`}>
                    <img
                        className="pri-img"
                        src={`https://admart.co.in/${product.image}`}
                        alt={product.name}
                        loading="lazy"
                        style={{ aspectRatio: "1/1" }}
                    />
                </Link>
            </figure>
            <div className="product-caption w-100">
                <p className="product-name">
                    <Link to={`${weburl}/product/${product.slug}`}>
                        {product.name}
                    </Link>
                </p>

                {attributes.length > 0 && (
                    <select
                        value={selectedAttribute?.id}
                        onChange={handleAttributeChange}
                        className="form-select mb-2"
                    >
                        {attributes.map((attr) => (
                            <option key={attr.id} value={attr.id}>
                                {attr.name} ({attr.stock_quantity} in stock)
                            </option>
                        ))}
                    </select>
                )}

                <div className="d-flex justify-content-between">
                    <div className="cartprice">
                        <div className="price-box d-flex flex-column">
                            {selectedAttribute && (
                                <>
                                    {selectedAttribute.discount_cost <
                                    selectedAttribute.cost ? (
                                        <>
                                            <span className="price-regular">
                                                ₹
                                                {
                                                    selectedAttribute.discount_cost
                                                }
                                            </span>
                                            <span className="price-old">
                                                <del>
                                                    ₹{selectedAttribute.cost}
                                                </del>
                                            </span>
                                        </>
                                    ) : (
                                        <span className="price-regular">
                                            ₹{selectedAttribute.cost}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div className="select-size border-0">
                        {!showQuantityControls ? (
                            <button
                                className="btn btn-primary px-3 py-2 text-white"
                                onClick={() =>
                                    handleAddToCart(
                                        selectedAttribute,
                                        product,
                                        shopId
                                    )
                                }
                            >
                                <i className="ri-shopping-cart-2-fill me-1"></i>{" "}
                                ADD
                            </button>
                        ) : (
                            <div className="custom-quantity-controls bg-primary rounded mb-2">
                                <button
                                    onClick={() =>
                                        handleQuantityChange(
                                            product,
                                            shopId,
                                            selectedAttribute,
                                            quantity - 1
                                        )
                                    }
                                    className="bg-transparent text-white"
                                >
                                    -
                                </button>
                                <span className="text-white px-2">
                                    {quantity}
                                </span>
                                <button
                                    onClick={() =>
                                        handleQuantityChange(
                                            product,
                                            shopId,
                                            selectedAttribute,
                                            quantity + 1
                                        )
                                    }
                                    className="bg-transparent text-white"
                                >
                                    +
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
