import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { weburl, routeurl } from "../helpers/ApiUrls";

const UserAccountMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate(); // Hook for navigation

    // Toggle menu visibility
    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    // Check user authorization status on component mount
    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (token) {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("authToken"); // Clear the auth token
        localStorage.removeItem("userId"); // Clear the user ID
        setIsAuthorized(false); // Update state
        toastr.success("You have been logged out successfully.");
        // Use navigate to go to the relative path
        const relativePath = weburl.replace(window.location.origin, ""); // Get relative path from weburl
        navigate(relativePath); // Redirect without refreshing the page
        //navigate('/'); // Redirect to home page
    };

    useEffect(() => {
        if (isAuthorized) {
            // Select the elements
            const myloginup = document.getElementById("myloginup");
            const loginbutton = document.getElementById("loginclick");
            const logoutclick = document.getElementById("logoutclick");

            if (logoutclick && isAuthorized) {
                logoutclick.style.display = "inline-flex"; // Show logout button
                logoutclick.addEventListener("click", function () {
                    handleLogout();
                    location.reload();
                });
            } else if (!isAuthorized) {
                logoutclick.style.display = "none"; // Show logout button
            }
            // Check if elements exist to avoid errors
            if (myloginup && loginbutton && isAuthorized) {
                myloginup.innerText = "Orders"; // Updated to "Orders" for proper capitalization

                // Add click event listener to the login button
                loginbutton.addEventListener("click", function () {
                    navigate(`${routeurl}account/orders`); // Redirect without refreshing the page
                });
            } else {
                console.error(
                    "Element not found: Ensure that 'myloginup' and 'loginclick' exist in the HTML."
                );
            }
        }
    }, [isAuthorized]);

    // Render nothing if not authorized
    if (!isAuthorized) return null;

    return (
        <div className="user-account-menu d-none">
            <button onClick={toggleMenu}>
                {isMenuOpen ? "Close Account Menu" : "Open Account Menu"}
            </button>
            {isMenuOpen && (
                <ul className="account-menu-list">
                    {/* <li><a href="/account/profile">Profile</a></li> */}
                    <li>
                        <Link to={`${weburl}/account/orders`}>Orders</Link>
                    </li>
                    {/*<li><a href="/account/settings">Settings</a></li>*/}
                    <li>
                        <button onClick={handleLogout}>Logout</button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default UserAccountMenu;
