import React from "react";
import { weburl } from "../helpers/ApiUrls";

const PolicySection = () => {
    return (
        <div>
            <section className="service-policy-area section-space pt-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            {/* start policy single item */}
                            <div className="service-policy-item">
                                <div className="icons">
                                    <img
                                        src={`${weburl}/frontend/assets/img/icon/free_shipping.png`}
                                        alt="Free Shipping"
                                    />
                                </div>
                                <h5>Free Shipping</h5>
                                <p>Free shipping on all orders</p>
                            </div>
                            {/* end policy single item */}
                        </div>
                        <div className="col-md-3 col-sm-6">
                            {/* start policy single item */}
                            <div className="service-policy-item">
                                <div className="icons">
                                    <img
                                        src={`${weburl}/frontend/assets/img/icon/money_back.png`}
                                        alt="Money Return"
                                    />
                                </div>
                                <h5>Money Return</h5>
                                <p>7 days for free return</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="service-policy-item">
                                <div className="icons">
                                    <img
                                        src={`${weburl}/frontend/assets/img/icon/support247.png`}
                                        alt="Online Support"
                                    />
                                </div>
                                <h5>Online Support</h5>
                                <p>Support 24 hours a day</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            {/* start policy single item */}
                            <div className="service-policy-item">
                                <div className="icons">
                                    <img
                                        src={`${weburl}/frontend/assets/img/icon/promotions.png`}
                                        alt="Deals & Promotions"
                                    />
                                </div>
                                <h5>Deals & Promotions</h5>
                                <p>Price savings, discounts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bannerbottom section-space pt-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <h2>
                                Stay home & get your daily
                                <br /> needs from our shop
                            </h2>
                            <p>
                                Start Your Daily Shopping with{" "}
                                <span>Admart</span>
                            </p>
                            <div className="box-cart">
                                <a
                                    href="#"
                                    className="btn btn-cart"
                                    tabIndex="0"
                                >
                                    Shop Now
                                </a>
                            </div>
                        </div>
                        <div className="col-4">
                            <img
                                src={`${weburl}/frontend/assets/img/petcare.avif`}
                                alt="Pet Care 4"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PolicySection;
