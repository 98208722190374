import React from "react";

const CategorySectionSkelton = () => {
    return (
        <div className="container-fluid ">
            <div id="homecateg">
                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block mb-4">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>

                <div className="d-block">
                    <div
                        className="skeleton "
                        style={{
                            height: "200px",
                            borderRadius: "20px",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CategorySectionSkelton;
