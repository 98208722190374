import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { weburl } from "../helpers/ApiUrls";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";
import "swiper/css/thumbs";
import { Pagination, Navigation, Thumbs, Zoom } from "swiper/modules";
import DOMPurify from "dompurify";
import "./ProductDetails.css";
import UserAccountMenu from "./UserAccountMenu"; // Import UserAccountMenu component
import CartSidebar from "./CartSidebar";
import CartInnerSidebar from "./CartInnerSidebar";
import LoaderSkeleton from "./skeleton/LoaderSkeleton";
import { useThemeContext } from "../helpers/ThemeContext";
//import './ProductDetailsPopup.css';

const ProductDetails = ({ latitude, longitude }) => {

    const {
        cartItems,
        handleUpdateCart,
        handleQuantityChange,
        handleAddToCart,
    } = useThemeContext();

    const { slug } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [message, setMessage] = useState("");
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [showQuantityControls, setShowQuantityControls] = useState(false);
    const [highlightedSection, setHighlightedSection] = useState(null);
    const [isDetailsCartOpen, setIsCartOpenDetails] = useState(false);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [isCartOpen, setIsCartOpen] = useState(false); // Ensure this is defined here
    //const [cartItems, setCartItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [attributes, setAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [stockQuantity, setStockQuantity] = useState(0);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [isZoomed, setIsZoomed] = useState(false);
    const [isUserAccountMenuVisible, setIsUserAccountMenuVisible] =
        useState(false); // Define the state here
    //console.log("attribute Valuesdsd:",selectedAttribute);
    useEffect(() => {
        const checkAuthStatus = () => {
            const token = localStorage.getItem("authToken"); // Or any key you use for storing the token
            if (token) {
                // You may also perform token validation here
                setIsUserAccountMenuVisible(true);
            } else {
                setIsUserAccountMenuVisible(false);
            }
        };

        checkAuthStatus();
    }, []);


    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            try {
                // Ensure the elements exist after the component has mounted
                await new Promise((resolve) => setTimeout(resolve, 0)); // Small delay to allow rendering

                const latitudeElement = document.getElementById("rec_latitute");
                const longitudeElement =
                    document.getElementById("rec_longitute");

                const latitudeValue = latitudeElement?.value || latitude;
                const longitudeValue = longitudeElement?.value || longitude;

                // Check if both latitude and longitude are available
                if (longitudeValue && latitudeValue) {
                    // Fetch the product using the values
                    const response = await axios.get(
                        `${weburl}/api/product/${slug}`,
                        {
                            params: {
                                latitude: latitudeValue,
                                longitude: longitudeValue,
                            },
                        }
                    );

                    console.log("API Response:", response);
                    if (response.data.product) {
                        setProduct(response.data.product);
                        setAttributes(response.data.product.product_attributes);
                        if (response.data.product.product_attributes.length > 0) {
                            setSelectedAttribute(response.data.product.product_attributes[0]);
                            setStockQuantity(
                                response.data.product.product_attributes[0].stock_quantity || 0
                            );
                        }
                    } else {
                        const cartDiv = document.getElementById("mycartup");
            if (cartDiv) {
                cartDiv.innerHTML = `My Cart`; // Change to desired text
            }
                handleUpdateCart([]); // Call the cart update function to clear the cart
            
            
                        setErrorMessage("Product not found");
                        console.error("Product not found");
                    }
                    {/*if (response.data.success) {
                        setProduct(response.data.product);
                    } else {
                        console.error("Product not found");
                    }*/}
                }
            } catch (error) {
                const cartDiv = document.getElementById("mycartup");
            if (cartDiv) {
                cartDiv.innerHTML = `My Cart`; // Change to desired text
            }
                handleUpdateCart([]); // Call the cart update function to clear the cart
                //window.location.reload();
                console.error("Error fetching product:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [slug, latitude, longitude]);
    useEffect(() => {
        if (product) {
            const firstAttribute = product.product_attributes[0];
            const existingItem = cartItems.find(
                (item) =>
                    item.product.id === product.id &&
                    item.shopId === selectedAttribute?.shop_id &&
                    item.attribute.id === selectedAttribute?.id
            );
            if (existingItem) {
                setQuantity(existingItem.quantity);
                setShowQuantityControls(true);
            } else {
                setQuantity(1);
                setShowQuantityControls(false);
            }
        }
    }, [cartItems, selectedAttribute,product]);
    

    const handleAttributeChange = (e) => {
        const selected = attributes.find(
            (attr) => attr.id === parseInt(e.target.value)
        );
        setSelectedAttribute(selected);
        setStockQuantity(selected.stock_quantity || 0);
    };
    // Check for existing item in cart and set initial quantity

    // Function to update cart in local storage and state
    
    
    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;
        setCursorPosition({ x, y });
    };

    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };
    
    const handleThumbnailClick = (index) => {
        setActiveSlide(index);
        thumbsSwiper.slideTo(index);
        swiperInstance.slideTo(index);
    };

   
    const openCartSidebar = () => setIsCartOpenDetails(true);
    const closeCartSidebar = () => setIsCartOpenDetails(false);
    if (loading) {
        return <LoaderSkeleton />;
    }

    if (!product) {
        return <LoaderSkeleton />;
    }
    const ucfirst = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    //console.log("check  Qty:",showQuantityControls);
    const openPhoneOtpModal = () => setIsPhoneOtpModalVisible(true);
    const closePhoneOtpModal = () => setIsPhoneOtpModalVisible(false);

    return (
        <main>
            <div className="product-details-inner">
                <div className="row">
                    <div className="col-lg-5">
                        <div
                            className="image-slider"
                            onMouseMove={handleMouseMove}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {/* Swiper for product images with zoom */}
                            <Swiper
                                modules={[Pagination, Navigation, Zoom]}
                                pagination={{ clickable: true }}
                                navigation
                                zoom={{ maxRatio: 3 }} // Configure zoom
                                className="swiper-container-main"
                                initialSlide={activeSlide}
                                onSlideChange={({ activeIndex }) =>
                                    setActiveSlide(activeIndex)
                                }
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                            >
                                {product.images.length > 0 ? (
                                    product.images.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <div
                                                className="image-wrapper"
                                                style={{
                                                    height: "100%",
                                                    aspectRatio: "1/1",
                                                }}
                                            >
                                                <img
                                                    src={image.image_path}
                                                    alt={`Product image ${
                                                        index + 1
                                                    }`}
                                                    className={`product-image ${
                                                        highlightedSection ===
                                                        index
                                                            ? "highlight"
                                                            : ""
                                                    }`}
                                                />
                                                {isZoomed && (
                                                    <div
                                                        className="zoom-overlay"
                                                        style={{
                                                            backgroundImage: `url(${image.image_path})`,
                                                            backgroundPosition: `${cursorPosition.x}% ${cursorPosition.y}%`,
                                                            backgroundSize:
                                                                "200%", // Adjust zoom level here
                                                            backgroundColor:
                                                                "white",
                                                            opacity: 1,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <SwiperSlide className="mythumb">
                                        <div className="image-wrapper">
                                            <img
                                                src={`${weburl}/${product.image}`}
                                                //src={product.image ? product.image : "default-image.png"}
                                                alt="Default product image"
                                                className="product-image"
                                                style={{
                                                    height: "100%",
                                                    aspectRatio: "1/1",
                                                }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                        {/* Thumbnails */}
                        <div className="thumbnail-slider">
                            <Swiper
                                modules={[Thumbs]}
                                onSwiper={setThumbsSwiper}
                                spaceBetween={10}
                                slidesPerView={5}
                                freeMode
                                className="swiper-container-thumbs"
                                breakpoints={{
                                    640: {
                                        slidesPerView: 3, // Adjust based on design
                                    },
                                    768: {
                                        slidesPerView: 4, // Adjust based on design
                                    },
                                    1024: {
                                        slidesPerView: 5,
                                    },
                                }}
                            >
                                {product.images.length > 0 ? (
                                    product.images.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="thumbnail-wrapper">
                                                <img
                                                    src={image.image_path}
                                                    alt={`Product image ${
                                                        index + 1
                                                    }`}
                                                    className="thumbnail-image"
                                                    onClick={() =>
                                                        handleThumbnailClick(
                                                            index
                                                        )
                                                    }
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <SwiperSlide>
                                        <div className="thumbnail-wrapper">
                                            <img
                                                src={`${weburl}/${product.image}`}
                                                alt="Default product image"
                                                className="thumbnail-image"
                                            />
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="product-details-des">
                            <h3 className="product-name">{product.name}</h3>
                            {/*<div className="price-box">
                                <span className="price-regular">
                                    ₹
                                    {
                                        product.product_attributes[0]
                                            ?.discount_cost
                                    }
                                </span>
                                <span className="price-old">
                                    <del>
                                        ₹{product.product_attributes[0]?.cost}
                                    </del>
                                </span>
                            </div>

                            <div className="availability">
                                <i className="fa fa-check-circle"></i>
                                <span>
                                    {
                                        product.product_attributes[0]
                                            .stock_quantity
                                    }{" "}
                                    in stock
                                </span>
                            </div>*/}
                              <div className="cartprice">
                        <div className="price-box d-flex flex-column">
                            {selectedAttribute && (
                                <>
                                    {selectedAttribute.discount_cost <
                                    selectedAttribute.cost ? (
                                        <>
                                            <span className="price-regular">
                                                ₹
                                                {
                                                    selectedAttribute.discount_cost
                                                }
                                            </span>
                                            <span className="price-old">
                                                <del>
                                                    ₹{selectedAttribute.cost}
                                                </del>
                                            </span>
                                        </>
                                    ) : (
                                        <span className="price-regular">
                                            ₹{selectedAttribute.cost}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                            <div style={{ maxWidth: 200 }} className="my-2">
                            {attributes.length > 0 && (
                    <select
                        value={selectedAttribute?.id}
                        onChange={handleAttributeChange}
                        className="form-select mb-2"
                    >
                        {attributes.map((attr) => (
                            <option key={attr.id} value={attr.id}>
                                {attr.name} ({attr.stock_quantity} in stock)
                            </option>
                        ))}
                    </select>
                )}
                            </div>
                          

                            <div className="quantity-cart-box">
                                {!showQuantityControls ? (
                                    <button
                                    className="btn btn-primary px-3 py-2 text-white"
                                    onClick={() =>
                                        handleAddToCart(
                                            selectedAttribute,
                                            product,
                                            product
                                                        .product_attributes[0]
                                                        .shop_id
                                        )
                                    }
                                >
                                    <i className="ri-shopping-cart-2-fill me-1"></i>{" "}
                                    ADD
                                </button>
                                ) : (
                                    <div className="custom-quantity-controls bg-primary rounded mb-2">
                                        <button
                                            className="bg-transparent text-white"
                                            onClick={() =>
                                                handleQuantityChange(
                                                    product,
                                                    product
                                                        .product_attributes[0]
                                                        .shop_id,
                                                        selectedAttribute,
                                                    quantity - 1
                                                )
                                            }
                                            disabled={quantity <= 1}
                                        >
                                            -
                                        </button>
                                        <span className="text-white px-2">
                                            {quantity}
                                        </span>
                                        <button
                                            className="bg-transparent text-white"
                                            onClick={() =>
                                                handleQuantityChange(
                                                    product,
                                                    product
                                                        .product_attributes[0]
                                                        .shop_id,
                                                        selectedAttribute,
                                                    quantity + 1
                                                )
                                            }
                                        >
                                            +
                                        </button>
                                        {errorMessage && (
                                            <p className="error-message">
                                                {errorMessage}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>

                            {message && (
                                <p className="cart-message">{message}</p>
                            )}

                            <p
                                className="pro-desc"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        product.description
                                    ),
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
            
            <CartSidebar
                cartItems={cartItems}
                onUpdateCart={handleUpdateCart}
                isOpen={isCartOpen}
                onClose={() => setIsCartOpen(false)}
                onProceedToCheckout={openPhoneOtpModal} // Pass down function to CartSidebar
            />

            {isUserAccountMenuVisible && <UserAccountMenu />}
        </main>
    );
};

export default ProductDetails;
