import React from "react";

const ShopSkeleton = ({ index }) => {
    return (
        <div className="row px-3 mt-4" key={index}>
            <div
                key={index}
                className="skeleton col-12 mb-2 "
                style={{
                    width: "100%",
                    maxWidth: "300px",
                    height: "40px",
                }}
            />
            <div className="row gap-4 mt-3">
                <div
                    key={index}
                    className="skeleton col-md-2 col-6 mb-2"
                    style={{
                        height: "250px",
                    }}
                />
                <div
                    key={index}
                    className="skeleton col-md-2 col-6 mb-2"
                    style={{
                        height: "250px",
                    }}
                />{" "}
                <div
                    key={index}
                    className="skeleton col-md-2 col-6 mb-2"
                    style={{
                        height: "250px",
                    }}
                />{" "}
                <div
                    key={index}
                    className="skeleton col-md-2 col-6 mb-2"
                    style={{
                        height: "250px",
                    }}
                />{" "}
                <div
                    key={index}
                    className="skeleton col-md-2 col-6 mb-2"
                    style={{
                        height: "250px",
                    }}
                />
            </div>
        </div>
    );
};

export default ShopSkeleton;
