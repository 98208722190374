import React from "react";
import { Link } from "react-router-dom";
import { weburl } from "../helpers/ApiUrls";

const CategorySection = ({ categories }) => {
    return (
        <section className="banner-statistics section-space overflow-auto">
            <div className="container-fluid">
                <div id="homecateg">
                    {categories.map((category, index) => (
                        <div key={index}>
                            <Link
                                to={`${weburl}/shop/${category.slug}`} // Use <Link> for client-side navigation
                                className="banner-item"
                            >
                                <figure className="banner-thumb">
                                    <img
                                        src={category.image}
                                        alt={category.name}
                                        height="300px"
                                        width="150px"
                                    />
                                    <figcaption className="banner-content">
                                        <h3 className="text2">
                                            {category.name}
                                        </h3>
                                    </figcaption>
                                </figure>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CategorySection;
