import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./ProductItem.css";
import { weburl } from "../helpers/ApiUrls";
import { useThemeContext } from "../helpers/ThemeContext";

const ProductItemCat = ({ product, shopId, updateSideCart }) => {
    const {
        cartItems,
        handleUpdateCart,
        handleQuantityChange,
        handleAddToCart,
    } = useThemeContext();

    const [attributes, setAttributes] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [showQuantityControls, setShowQuantityControls] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [stockQuantity, setStockQuantity] = useState(0);
    //const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [message, setMessage] = useState("");

    // Fetch product attributes
    useEffect(() => {
        const fetchAttributes = async () => {
            try {
                const response = await axios.get(
                    `${weburl}/api/attributes/${product.id}/${shopId}`
                );
                if (response.data.success) {
                    setAttributes(response.data.attributes);
                    if (response.data.attributes.length > 0) {
                        setSelectedAttribute(response.data.attributes[0]);
                        setStockQuantity(
                            response.data.attributes[0].stock_quantity || 0
                        );
                    }
                } else {
                    console.error(
                        "Failed to fetch attributes:",
                        response.data.message
                    );
                }
            } catch (error) {
                console.error("Error fetching attributes:", error);
            }
        };

        fetchAttributes();
    }, [product.id, shopId]);

    // Check for existing item in cart and set initial quantity
    useEffect(() => {
        const existingItem = cartItems.find(
            (item) =>
                item.product.id === product.id &&
                item.shopId === shopId &&
                item.attribute.id === selectedAttribute?.id
        );

        if (existingItem) {
            setQuantity(existingItem.quantity);
            setShowQuantityControls(true);
        } else {
            setQuantity(1);
            setShowQuantityControls(false);
        }
    }, [cartItems, selectedAttribute, product.id, shopId]);

    // Function to update cart in local storage and state
    {
        /*const updateCart = useCallback(
        (cartItems) => {
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
            updateSideCart(cartItems);
        },
        [updateSideCart]
    );*/
    }

    const handleAttributeChange = (e) => {
        const selected = attributes.find(
            (attr) => attr.id === parseInt(e.target.value)
        );
        setSelectedAttribute(selected);
        setStockQuantity(selected.stock_quantity || 0);
    };

    return (
        <div className="product-item">
            <figure className="product-thumb">
                <Link to={`${weburl}/product/${product.slug}`}>
                    <img
                        className="pri-img"
                        src={`${weburl}/${product.image}`}
                        alt={product.name}
                    />
                </Link>

                <div className="product-badge">
                    <div className="product-label new">
                        <span>new</span>
                    </div>
                </div>
            </figure>
            <div className="product-caption">
                <p className="product-name mb-0">
                    <Link to={`${weburl}/product/${product.slug}`}>
                        {product.name}
                    </Link>
                </p>

                {attributes.length > 0 && (
                    <select
                        value={selectedAttribute?.id}
                        onChange={handleAttributeChange}
                        className="form-select mb-2"
                    >
                        {attributes.map((attr) => (
                            <option key={attr.id} value={attr.id}>
                                {attr.name} ({attr.stock_quantity} in stock)
                            </option>
                        ))}
                    </select>
                )}

                <div className="d-flex justify-content-between">
                    <div className="cartprice">
                        <div className="price-box d-flex flex-column">
                            {selectedAttribute && (
                                <>
                                    {selectedAttribute.discount_cost <
                                    selectedAttribute.cost ? (
                                        <>
                                            <span className="price-regular">
                                                ₹
                                                {
                                                    selectedAttribute.discount_cost
                                                }
                                            </span>
                                            <span className="price-old">
                                                <del>
                                                    ₹{selectedAttribute.cost}
                                                </del>
                                            </span>
                                        </>
                                    ) : (
                                        <span className="price-regular">
                                            ₹{selectedAttribute.cost}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {attributes.length > 0 && (
                        <div className="select-size border-0">
                            {!showQuantityControls ? (
                                <button
                                    className="btn btn-primary px-3 py-2 text-white"
                                    onClick={() =>
                                        handleAddToCart(
                                            selectedAttribute,
                                            product,
                                            shopId
                                        )
                                    }
                                >
                                    <i className="ri-shopping-cart-2-fill me-1"></i>{" "}
                                    ADD
                                </button>
                            ) : (
                                <div className="custom-quantity-controls bg-primary rounded mb-2">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleQuantityChange(
                                                product,
                                                shopId,
                                                selectedAttribute,
                                                quantity - 1
                                            )
                                        }
                                        //disabled={quantity <= 1}
                                        className="bg-transparent text-white"
                                        style={{ cursor: "pointer" }}
                                    >
                                        -
                                    </button>
                                    <span className="text-white px-2">
                                        {" "}
                                        {quantity}
                                    </span>
                                    <button
                                        style={{ cursor: "pointer" }}
                                        type="button"
                                        onClick={() =>
                                            handleQuantityChange(
                                                product,
                                                shopId,
                                                selectedAttribute,
                                                quantity + 1
                                            )
                                        }
                                        //disabled={quantity >= stockQuantity}
                                        className="bg-transparent text-white"
                                    >
                                        +
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {message && (
                    <div
                        className="alert alert-danger alert-dismissible fade show px-1 py-1"
                        style={{ fontSize: 15, lineHeight: "1.2em" }}
                    >
                        {message}
                        <button
                            type="button"
                            className="bg-danger rounded-circle p-0 ms-2"
                            onClick={() => setMessage("")}
                            style={{ width: 20, height: 20 }}
                        >
                            <i className="ri-close-line "></i>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductItemCat;
