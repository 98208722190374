import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SliderSection = ({ slider }) => {
  return (
    <section>
      <div className="swiper mySwiper">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          className="swiper-wrapper"
        >
          {slider?.slider_one && (
            <SwiperSlide className="swiper-slide">
              <img src={slider.slider_one} alt="Slider One" />
            </SwiperSlide>
          )}
          {slider?.slider_two && (
            <SwiperSlide className="swiper-slide">
              <img src={slider.slider_two} alt="Slider Two" />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </section>
  );
};

export default SliderSection;
