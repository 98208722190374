// OtpInput.jsx

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./OtpInput.css"; // Ensure this file exists for styling

const OtpInput = ({ value, onChange }) => {
    const [otp, setOtp] = useState(value || "");
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        // Automatically focus the next input when a digit is entered
        const currentInput = otp.length;
        if (currentInput < 4) {
            inputRefs[currentInput].current.focus();
        }
    }, [otp]);

    const handleChange = (e, index) => {
        const newValue = e.target.value;
        if (/^\d$/.test(newValue) || newValue === "") {
            const newOtp =
                otp.slice(0, index) + newValue + otp.slice(index + 1);
            setOtp(newOtp);
            onChange(newOtp);

            // Move focus to the next input
            if (newValue !== "") {
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.length) {
                    inputRefs[nextIndex].current.focus();
                }
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "" && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    return (
        <div className="otp-input">
            {Array.from({ length: 4 }, (_, index) => (
                <input
                    key={index}
                    ref={inputRefs[index]}
                    type="tel"
                    value={otp[index] || ""}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    maxLength="1"
                    aria-label={`OTP digit ${index + 1}`}
                />
            ))}
        </div>
    );
};

OtpInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default OtpInput;
