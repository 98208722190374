import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import "./CartSidebar.css";
import PhoneOtpModal from "./PhoneOtpModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { weburl } from "../helpers/ApiUrls";
import { Link } from "react-router-dom";

const CartInnerSidebar = ({
    cartItems,
    onUpdateCart,
    isOpen,
    onClose,
    onProceedToCheckout,
}) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isPhoneOtpModalVisible, setIsPhoneOtpModalVisible] = useState(false);
    const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
    const [checkoutData, setCheckoutData] = useState({
        addressType: "",
        flatHouseNo: "",
        floor: "",
        landmark: "",
        name: "",
        phone: "",
        payLater: false,
    });
    // Retrieve user details from localStorage
    const userId = localStorage.getItem("userId");
    const userPhone = localStorage.getItem("phoneNumber"); // Assuming you have saved phone number here
    const tokenSto = localStorage.getItem("authToken");
    const navigate = useNavigate();
   
    useEffect(() => {
        if (!isOpen) {
            setIsMinimized(true);
        }
    }, [isOpen]);

    const handleQuantityChange = (product, shopId, attribute, newQuantity) => {
        if (newQuantity < 1 || !Number.isInteger(newQuantity)) {
            setErrorMessage("Invalid quantity");
            return;
        }

        const stockQuantity = attribute.stock_quantity || Infinity;

        if (newQuantity > stockQuantity) {
            setErrorMessage(`Cannot exceed stock quantity of ${stockQuantity}`);
            return;
        }

        setErrorMessage("");

        let updatedCartItems;

        if (newQuantity < 1) {
            updatedCartItems = cartItems.filter(
                (item) =>
                    !(
                        item.product.id === product.id &&
                        item.shopId === shopId &&
                        item.attribute.id === attribute.id
                    )
            );
        } else {
            updatedCartItems = cartItems.map((item) => {
                if (
                    item.product.id === product.id &&
                    item.shopId === shopId &&
                    item.attribute.id === attribute.id
                ) {
                    return { ...item, quantity: newQuantity };
                }
                return item;
            });
        }

        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
        if (typeof onUpdateCart === "function") {
            onUpdateCart(updatedCartItems);
        }
    };

    const handleDeleteItem = (product, shopId, attribute) => {
        const updatedCartItems = cartItems.filter(
            (item) =>
                !(
                    item.product.id === product.id &&
                    item.shopId === shopId &&
                    item.attribute.id === attribute.id
                )
        );

        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
        if (typeof onUpdateCart === "function") {
            onUpdateCart(updatedCartItems);
        }
    };

    const totalAmount = cartItems.reduce(
        (acc, item) =>
            acc + (item.attribute.discount_cost || 0) * item.quantity,
        0
    );
    const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

    const showPhoneOtpModal = () => {
        setIsPhoneOtpModalVisible(true);
    };

    const hidePhoneOtpModal = () => {
        setIsPhoneOtpModalVisible(false);
    };

    const handleCheckout = () => {
        const tokenSto = localStorage.getItem("authToken"); // Check if token exists
        if (tokenSto) {
            setIsCheckoutVisible(true);
        } else {
            showPhoneOtpModal();
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCheckoutData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };
    //const cartItemsArray = Array.isArray(cartItems) ? cartItems : Object.values(cartItems);
    //const cartItemsArray = Object.keys(cartItems).map((key) => cartItems[key]);
    const cartItemsArray = Array.isArray(cartItems) ? cartItems : Object.values(cartItems);

    const handleSaveAddress = async (e) => {
        e.preventDefault();
        const userId = localStorage.getItem("userId");
        // Prepare the data to be sent to the server
        const orderData = {
            ...checkoutData,
            cartItems: JSON.stringify(cartItemsArray), // Convert to JSON string
            //cartItems: cartItemsArray,
            totalAmount: totalAmount,
            user_id: userId, // Ensure user_id is included here
        };

        console.log("Order Data:", orderData); // Debugging line

        try {
            // Send the data to the server
            const response = await fetch(`${weburl}/api/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                    "X-CSRF-TOKEN": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
                body: JSON.stringify(orderData), // Convert orderData to JSON string
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Order placed successfully:", data);
                toastr.success("Order placed successfully!");
                // If the order was successfully created, clear the cart in localStorage
                localStorage.removeItem("cartItems");

                // Optionally, clear the cart state in your application
                onUpdateCart([]);
                setIsCheckoutVisible(false);
                // Redirect to the orders page without refreshing the browser
                navigate("/admart/account/orders");

                //navigate("${weburl}/account/orders");  // Change the path to the actual orders route
                // Trigger the checkout process or redirect the user
                //onProceedToCheckout();
            } else {
                // Handle server errors
                const errorData = await response.json();
                console.error(
                    "Order creation failed:",
                    error.response.data.error || error.response.data.message
                );

                //console.error('Order creation failed:', errorData.message);
                // Show error message with toastr
                toastr.error(
                    "Order creation failed: " +
                        (errorData.message || "Unknown error")
                );
            }
        } catch (error) {
            console.error("Error saving order:", error);
        }
    };

    const tokenCheckout = localStorage.getItem("authToken"); // Check if token exists

    const toggleCart = () => {
        const cart = document.getElementById("mycart");
        if (cart) {
            cart.classList.toggle("show");
        }
    };
    
    return (
        
        <div className="cart-slide" id="mycart">
            
            <div className="d-flex flex-column" style={{ height: "100vh" }}>
                <div className="d-flex align-items-center p-4">
                
                    <button
                        className="close-button bg-white"
                        aria-label="Close"
                        onClick={toggleCart}
                    >
                        <span aria-hidden="true" className="h2">
                            &times;
                        </span>
                        

                    </button>
                    <h4>Shopping Cart</h4>
                </div>
                <div
                    className={`bg-light p-3 cart-sidebar h-100`}
                    style={{ overflow: "auto" }}
                >
                    <ul>
                        {cartItems.map((item) => {
                            const discountCost =
                                Number(item.attribute.discount_cost) || 0;
                            const originalCost =
                                Number(item.attribute.cost) || 0;

                            return (
                                <li
                                    key={`${item.product.id}-${item.attribute.id}-${item.shopId}`}
                                    className="cart-item bg-white p-3 rounded border"
                                >
                                    <img
                                        src={`${weburl}/${item.product.image}`}
                                        alt={item.product.name}
                                        className="cart-item-image"
                                    />
                                    <div className="cart-item-details">
                                        <p>{item.product.name}</p>
                                        <p>Attribute: {item.attribute.name}</p>
                                        <p>
                                            <span className="discounted-price">
                                                ₹{discountCost.toFixed(2)}
                                            </span>
                                            <span className="original-price">
                                                ₹{originalCost.toFixed(2)}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="d-block">
                                        <div className="custom-quantity-controls bg-primary rounded mb-2">
                                            <button
                                                className="bg-transparent"
                                                onClick={() =>
                                                    handleQuantityChange(
                                                        item.product,
                                                        item.shopId,
                                                        item.attribute,
                                                        item.quantity - 1
                                                    )
                                                }
                                                aria-label="Decrease quantity"
                                                disabled={item.quantity <= 1}
                                            >
                                                -
                                            </button>
                                            <span className="text-white px-2">
                                                {item.quantity}
                                            </span>
                                            <button
                                                className="bg-transparent"
                                                onClick={() =>
                                                    handleQuantityChange(
                                                        item.product,
                                                        item.shopId,
                                                        item.attribute,
                                                        item.quantity + 1
                                                    )
                                                }
                                                aria-label="Increase quantity"
                                                disabled={
                                                    item.quantity >=
                                                    (item.attribute
                                                        .stock_quantity ||
                                                        Infinity)
                                                }
                                            >
                                                +
                                            </button>
                                            {errorMessage && (
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            )}
                                        </div>

                                        <button
                                            onClick={() =>
                                                handleDeleteItem(
                                                    item.product,
                                                    item.shopId,
                                                    item.attribute
                                                )
                                            }
                                            className="delete-button w-100 rounded"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    {errorMessage && (
                        <div className="error-message">{errorMessage}</div>
                    )}
                    <div className="d-block "></div>

                    <div
                        className={`cart-summary ${
                            cartItems.length === 0 && "border-0"
                        }`}
                    >
                        <h5>Total Items: {totalItems}</h5>
                        <h5>Total Amount: ₹{totalAmount.toFixed(2)}</h5>
                    </div>

                    {isPhoneOtpModalVisible && !tokenCheckout && (
                        <PhoneOtpModal
                            isOpen={isPhoneOtpModalVisible}
                            onClose={hidePhoneOtpModal}
                            onVerify={(phoneNumber) => {
                                hidePhoneOtpModal();
                            }}
                        />
                    )}
                    {isPhoneOtpModalVisible && (
                        <PhoneOtpModal onClose={hidePhoneOtpModal} />
                    )}
                    {isCheckoutVisible && tokenCheckout && (
                        <div className="checkout-form">
                            <h4>Checkout</h4>
                            <form onSubmit={handleSaveAddress}>
                                <div>
                                    <label className="w-100 mb-2">
                                        Save Address As
                                        <select
                                            name="addressType"
                                            className="form-control w-100"
                                            value={checkoutData.addressType}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Home">Home</option>
                                            <option value="Work">Work</option>
                                            <option value="Hotel">Hotel</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Flat/House No/Building Name
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            name="flatHouseNo"
                                            value={checkoutData.flatHouseNo}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Floor (Optional)
                                        <input
                                            type="text"
                                            name="floor"
                                            className="form-control w-100"
                                            value={checkoutData.floor}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Nearby Landmark
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            name="landmark"
                                            value={checkoutData.landmark}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Your Name
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control w-100"
                                            value={checkoutData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Phone Number
                                        <input
                                            type="text"
                                            name="phone"
                                            className="form-control w-100"
                                            value={userPhone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="payLater"
                                            checked={checkoutData.payLater}
                                            onChange={handleChange}
                                        />
                                        Pay Later
                                    </label>
                                </div>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary py-2 px-3 me-auto w-100 mt-2 "
                                    >
                                        SAVE AND CHECKOUT
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                <div className="d-block p-2">
                    {cartItems.length === 0 ? (
                        <Link
                            to={weburl}  // Correct interpolation of variable
                            className="btn btn-dark bg-dark py-2 px-3 w-100"
                            disabled={cartItems.length === 0}
                            onClick={handleCheckout}
                        >
                            <i className="ri-home-3-fill"></i> Go Home
                        </Link>
                    ) : (
                        <button
                            className="checkout-button d-flex justify-content-between gap-2 align-items-center"
                            disabled={cartItems.length === 0}
                            onClick={handleCheckout}
                        >
                            <div className="d-block">
                                <p
                                    className="p m-0 fw-bold"
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    ₹{totalAmount.toFixed(2)}
                                </p>
                                <p
                                    className="p m-0"
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    TOTAL{" "}
                                </p>
                            </div>
                            <span className="h5 ">
                                {" "}
                                Proceed to Checkout{" "}
                                <i className="ri-arrow-right-s-line"></i>
                            </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

CartInnerSidebar.propTypes = {
    cartItems: PropTypes.array.isRequired,
    onUpdateCart: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProceedToCheckout: PropTypes.func.isRequired,
};

export default CartInnerSidebar;
