// axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://admart.co.in",
});
export default axiosInstance;

export const weburl = "https://admart.co.in";
export const routeurl = "/";
// export const weburl = process.env.REACT_APP_API_URL || "http://localhost/admart";
