import React from "react";

const SliderSectionSkelton = () => {
    return (
        <div
            className="skeleton mb-4"
            style={{
                width: "100%",
                borderRadius: "0px",
                height: "100%",
                aspectRatio: "1519/407",
            }}
        ></div>
    );
};

export default SliderSectionSkelton;
